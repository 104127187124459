import React from "react";
import { Switch, Link, useParams } from "react-router-dom";
import {
  Button,
  Level,
  Columns,
  Card,
  Title,
  ReactCalendar,
} from "@selfdetermine/react-elements";
import { Form } from "@selfdetermine/react-forms";
import {
  HearingCard,
  useHearings,
  DocketTable,
} from "@selfdetermine/react-hearings";
import moment from "moment";
import { RestrictedRoute } from "../RestrictedRoute";
import { Page } from "../Layout";

const IndexPage = () => {
  const [filters, setFilters] = React.useState({});
  const [dateTime] = React.useState(new Date());
  const [hearings, hearingsMeta] = useHearings(filters);
  const handleSubmit = (form) => {
    setFilters(form);
  };

  const formatDate = (string, formatString) => {
    return moment(string).format(formatString);
  };

  const handleDayClick = (value) => {
    setFilters({ date: formatDate(value, "YYYY-MM-DD") });
  };
  return (
    <Page
      title="Hearings"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Hearings", to: "/hearings" },
      ]}
    >
      <Page.Section>
        <Level>
          <Level.Right>
            <Button
              as={Link}
              to={`/hearings/docket/${filters.date}`}
              disabled={!!(!hearings.length && !filters)}
            >
              <span>View Docket</span>
            </Button>
          </Level.Right>
        </Level>
        <Card>
          <Card.Content>
            <ReactCalendar
              onClickDay={handleDayClick}
              value={dateTime}
              tileDisabled={({ date }) =>
                date.getDay() === 0 || date.getDay() === 6
              }
            />
          </Card.Content>
        </Card>
        <Columns>
          <Columns.Column size="3">
            <Card>
              <Card.Header title="Search" />
              <Card.Content>
                <Form onSubmit={handleSubmit} loading={hearingsMeta.loading}>
                  <Form.Input type="date" name="date" label="Date" />
                  <Level mobile>
                    <Level.Right>
                      <Level.Item>
                        <Form.SubmitButton>Search</Form.SubmitButton>
                      </Level.Item>
                    </Level.Right>
                  </Level>
                </Form>
              </Card.Content>
            </Card>
          </Columns.Column>
          <Columns.Column>
            {!hearings.length && (
              <Title size="2">
                {!hearings.length && !filters
                  ? "No hearings scheduled"
                  : "Please select a date"}
              </Title>
            )}
            {hearings.map((hearing) => (
              <Link to={`/hearings/${hearing.id}`} key={hearing.id}>
                <HearingCard hearing={hearing} />
              </Link>
            ))}
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

const DocketPage = () => {
  const { date } = useParams();
  const [hearings] = useHearings({ date });

  return (
    <Page
      title="Hearings Docket"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Hearings", to: "/hearings" },
        { title: "Docket", to: "/hearings/docket" },
      ]}
    >
      {hearings.length ? (
        <Page.Section>
          <DocketTable
            hearings={hearings}
            date={moment(date).format("MM-DD-YYYY")}
          />
        </Page.Section>
      ) : (
        <Page.Section>
          <Title>Docket is building...</Title>
        </Page.Section>
      )}
    </Page>
  );
};

export const Hearings = () => {
  return (
    <Switch>
      <RestrictedRoute
        exact
        path="/hearings"
        component={IndexPage}
        permission="hearing.index"
      />
      <RestrictedRoute
        exact
        path="/hearings/docket/:date"
        component={DocketPage}
        permission="hearing.index"
      />
    </Switch>
  );
};
