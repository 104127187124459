import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { usePermission } from "@selfdetermine/react-permissions";
import { Toasts } from "@selfdetermine/react-toasts";
import { PageNotFound } from "./pages/PageNotFound";

/**
 * Wrapped router that redirects the user if they're not supposed to have access to the content
 */
export const RestrictedRoute = ({
  exact,
  path,
  permission: permissionProp,
  fallbackTo = "",
  component: componentProp,
}) => {
  const hasAccess = usePermission(permissionProp);

  if (!hasAccess && fallbackTo) {
    Toasts.warning({
      title: "Content unavailable, redirecting you.",
      description:
        "The content either doesn't exist, or you don't have permission to view it.",
    });
    return <Redirect to={fallbackTo} />;
  }

  return (
    <Route
      exact={exact}
      path={path}
      component={hasAccess ? componentProp : PageNotFound}
    />
  );
};

RestrictedRoute.propTypes = {
  /** If the path must match exactly in order to render */
  exact: PropTypes.bool,
  /** Path the route renders based on */
  path: PropTypes.string,
  /** Name of permission to check */
  permission: PropTypes.string.isRequired,
  /** Component to render if the user has permission */
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  /** Path to redirect to if the user doesn't have permission */
  fallbackTo: PropTypes.string,
};
