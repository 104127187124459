import React from "react";
import { MoveFocusInside } from "react-focus-lock";
import { Switch, Link, useHistory, useParams } from "react-router-dom";
import { Toasts } from "@selfdetermine/react-toasts";
import {
  Button,
  Level,
  Columns,
  Media,
  Icon,
  Card,
  Title,
} from "@selfdetermine/react-elements";
import { Form } from "@selfdetermine/react-forms";
import {
  VehicleCard,
  useVehicle,
  useVehicles,
  VehicleForm,
} from "@selfdetermine/react-vehicles";
import { RestrictedRoute } from "../RestrictedRoute";
import { Page } from "../Layout";

const CreatePage = () => {
  const history = useHistory();

  return (
    <Page
      title="New Vehicle"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Vehicles", to: "/vehicles" },
        { title: "New", to: "/vehicles/new" },
      ]}
    >
      <Page.Section>
        <Columns centered>
          <Columns.Column size="6">
            <MoveFocusInside>
              <VehicleForm
                onDone={() => {
                  Toasts.success("New vehicle created");
                  history.push("/vehicles");
                }}
                onCancel={() => history.push("/vehicles")}
              />
            </MoveFocusInside>
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

const EditPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const [vehicle, vehicleMeta] = useVehicle({ id });

  if (!vehicle && !vehicleMeta.loading) {
    history.push("/vehicles");
  }

  if (vehicle.loading) {
    return false;
  }

  return (
    <Page
      title={vehicle?.name}
      subtitle="Update"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Vehicles", to: "/vehicles" },
        { title: vehicle?.name, to: `/vehicles/${vehicle?.id}` },
        { title: "Update", to: `/vehicles/${vehicle?.id}/edit` },
      ]}
    >
      <Page.Section>
        <Columns centered>
          <Columns.Column size="6">
            <MoveFocusInside>
              <VehicleForm
                defaultValues={vehicle}
                onDone={() => {
                  Toasts.success("Vehicle updated");
                  history.push(`/vehicles/${vehicle?.id}`);
                }}
                onCancel={() => history.push(`/vehicles/${vehicle?.id}`)}
              />
            </MoveFocusInside>
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

const DetailsPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const [vehicle, vehicleMeta] = useVehicle({ id });

  if (!vehicle && !vehicleMeta.loading) {
    history.push("/vehicles");
  }

  return (
    <Page
      subtitle="Vehicle Details"
      title={vehicle?.name}
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Vehicles", to: "/vehicles" },
        { title: vehicle?.name, to: `/vehicles/${vehicle?.id}` },
      ]}
    >
      {vehicle ? (
        <>
          <Page.Section>
            <Level mobile>
              <Level.Right>
                <Level.Item>
                  <Button as={Link} to={`/vehicles/${vehicle.id}/edit`}>
                    <Icon name="pencil" />
                    <span>Edit</span>
                  </Button>
                </Level.Item>
              </Level.Right>
            </Level>
          </Page.Section>
          <Page.Section>
            <Media>
              <Media.Left>
                <Icon name="car" />
              </Media.Left>
              <Media.Content>
                <Title size="4">Vehicle Information</Title>
                <div>
                  {[vehicle.color, vehicle.year, vehicle.make, vehicle.model]
                    .filter(Boolean)
                    .join(" ")}
                </div>
                <div>{vehicle.vin}</div>
              </Media.Content>
            </Media>
            <Media>
              <Media.Left>
                <Icon name="tag" />
              </Media.Left>
              <Media.Content>
                <Title size="4">License Plate</Title>
                {[vehicle.licenseState, vehicle.licenseNumber]
                  .filter(Boolean)
                  .join(" - ") || "Not available"}
              </Media.Content>
            </Media>
          </Page.Section>
        </>
      ) : null}
    </Page>
  );
};

const IndexPage = () => {
  const [filters, setFilters] = React.useState({});
  const [vehicles, vehiclesMeta] = useVehicles({ filters });

  return (
    <Page
      title="Vehicles"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Vehicles", to: "/vehicles" },
      ]}
    >
      <Page.Section>
        <Level>
          <Level.Right>
            <Button as={Link} to="/vehicles/new" color="success">
              <Icon name="plus" />
              <span>New Vehicle</span>
            </Button>
          </Level.Right>
        </Level>
        <Columns>
          <Columns.Column size="3">
            <Card>
              <Card.Header title="Search" />
              <Card.Content>
                <Form
                  disabled
                  onSubmit={setFilters}
                  loading={vehiclesMeta.loading}
                >
                  <Form.Input
                    type="text"
                    name="licenseNumber"
                    label="License Number"
                  />
                  <Form.Input type="text" name="licenseState" label="State" />
                  <Form.Input type="text" name="make" label="Vehicle Make" />
                  <Form.Input type="text" name="model" label="Vehicle Model" />
                  <Form.Input
                    name="year"
                    label="Vehicle Year"
                    type="number"
                    step="1"
                    min="1900"
                  />
                  <Level mobile>
                    <Level.Right>
                      <Level.Item>
                        <Form.SubmitButton>Search</Form.SubmitButton>
                      </Level.Item>
                    </Level.Right>
                  </Level>
                </Form>
              </Card.Content>
            </Card>
          </Columns.Column>
          <Columns.Column>
            {vehicles.map((vehicle) => (
              <Link to={`/vehicles/${vehicle.id}`} key={vehicle.id}>
                <VehicleCard vehicle={vehicle} />
              </Link>
            ))}
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

export const Vehicles = () => {
  return (
    <Switch>
      <RestrictedRoute
        exact
        path="/vehicles/new"
        component={CreatePage}
        permission="vehicle.create"
      />
      <RestrictedRoute
        exact
        path="/vehicles/:id/edit"
        component={EditPage}
        permission="vehicle.edit"
      />
      <RestrictedRoute
        exact
        path="/vehicles/:id"
        component={DetailsPage}
        permission="vehicle.read"
      />
      <RestrictedRoute
        exact
        path="/vehicles"
        component={IndexPage}
        permission="vehicle.index"
      />
    </Switch>
  );
};
