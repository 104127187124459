import React from "react";
import PropTypes from "prop-types";
import { Link, Switch, useRouteMatch, useHistory } from "react-router-dom";
import { Toasts } from "@selfdetermine/react-toasts";
import {
  Search,
  NewCriminalCaseForm,
  NewCivilCaseForm,
} from "@selfdetermine/react-matters";
import { Columns, Level, Icon, Dropdown } from "@selfdetermine/react-elements";
import { PermissionRequired } from "@selfdetermine/react-permissions";
import { RestrictedRoute } from "../RestrictedRoute";
import { Page } from "../Layout";
import { Details } from "./matters/Details";

const defaultBreadcrumbs = [
  { title: "Home", to: "/" },
  { title: "Matters", to: "/matters" },
];

const NewMatterPage = ({ type }) => {
  const match = useRouteMatch();
  const history = useHistory();

  const title = type === "criminal" ? "criminal case" : "civil case";
  const CreateForm =
    type === "criminal" ? NewCriminalCaseForm : NewCivilCaseForm;
  return (
    <Page
      title={title}
      breadcrumbs={[
        ...defaultBreadcrumbs,
        { title: `New ${title}`, to: match.path },
      ]}
    >
      <Page.Section>
        <Columns centered>
          <Columns.Column size="8">
            <CreateForm
              onCancel={() => {
                history.push("/matters");
              }}
              onDone={(response) => {
                Toasts.success(`New ${title} created`);
                history.push(`/matters/${response?.data?.matter?.id}`);
              }}
            />
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

NewMatterPage.propTypes = {
  type: PropTypes.oneOf(["criminal", "civil"]),
};

const MattersPage = () => (
  <Page title="Matters" breadcrumbs={defaultBreadcrumbs}>
    <Page.Section>
      <Level>
        <Level.Left />
        <Level.Right>
          <PermissionRequired name="matter.create">
            <Level.Item>
              <Dropdown alignment="right">
                <Dropdown.Trigger
                  color="success"
                  aria-label="create new matter"
                  removeIndicator
                >
                  <Icon name="plus" />
                  <span>New Matter</span>
                </Dropdown.Trigger>
                <Dropdown.Content>
                  <Dropdown.Item as={Link} to="/matters/criminal/new">
                    Criminal Case
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/matters/civil/new">
                    Civil Case
                  </Dropdown.Item>
                </Dropdown.Content>
              </Dropdown>
            </Level.Item>
          </PermissionRequired>
        </Level.Right>
      </Level>
      <Search />
    </Page.Section>
  </Page>
);

export const Matters = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <RestrictedRoute
        path="/matters/criminal/new"
        permission="matter.create"
        component={() => <NewMatterPage type="criminal" />}
      />
      <RestrictedRoute
        path="/matters/civil/new"
        permission="matter.create"
        component={() => <NewMatterPage type="civil" />}
      />
      <RestrictedRoute
        path={[match.path, ":id"].join("/")}
        permission="matter.show"
        component={Details}
      />
      <RestrictedRoute
        path={match.path}
        permission="matter.index"
        component={MattersPage}
        fallbackTo="/"
      />
    </Switch>
  );
};
