import React from "react";
import { MoveFocusInside } from "react-focus-lock";
import { Switch, Link, useHistory, useParams } from "react-router-dom";
import { Toasts } from "@selfdetermine/react-toasts";
import {
  Button,
  Level,
  Columns,
  Media,
  Icon,
  Card,
  Title,
} from "@selfdetermine/react-elements";
import { Form } from "@selfdetermine/react-forms";
import {
  ChargeCard,
  useCharge,
  useCharges,
  ChargeForm,
} from "@selfdetermine/react-charges";
import { RestrictedRoute } from "../RestrictedRoute";
import { Page } from "../Layout";

const CreatePage = () => {
  const history = useHistory();

  return (
    <Page
      title="New Charge"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Charges", to: "/charges" },
        { title: "New", to: "/charges/new" },
      ]}
    >
      <Page.Section>
        <Columns centered>
          <Columns.Column size="6">
            <MoveFocusInside>
              <ChargeForm
                onDone={() => {
                  Toasts.success("New charge created");
                  history.push("/charges");
                }}
                onCancel={() => history.push("/charges")}
              />
            </MoveFocusInside>
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

const EditPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const [charge, chargeMeta] = useCharge({ id });

  if (!charge && !chargeMeta.loading) {
    history.push("/charges");
  }

  if (charge.loading) {
    return false;
  }

  return (
    <Page
      title={charge?.name}
      subtitle="Update"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Charges", to: "/charges" },
        { title: charge?.name, to: `/charges/${charge?.id}` },
        { title: "Update", to: `/charges/${charge?.id}/edit` },
      ]}
    >
      <Page.Section>
        <Columns centered>
          <Columns.Column size="6">
            <MoveFocusInside>
              <ChargeForm
                defaultValues={charge}
                onDone={() => {
                  Toasts.success("Charge updated");
                  history.push(`/charges/${charge?.id}`);
                }}
                onCancel={() => history.push(`/charges/${charge?.id}`)}
              />
            </MoveFocusInside>
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

const DetailsPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const [charge, chargeMeta] = useCharge({ id });

  if (!charge && !chargeMeta.loading) {
    history.push("/charges");
  }

  return (
    <Page
      subtitle="Charge Details"
      title={charge?.name}
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Charges", to: "/charges" },
        { title: charge?.name, to: `/charges/${charge?.id}` },
      ]}
    >
      {charge ? (
        <>
          <Page.Section>
            <Level mobile>
              <Level.Right>
                <Level.Item>
                  <Button as={Link} to={`/charges/${charge.id}/edit`}>
                    <Icon name="pencil" />
                    <span>Edit</span>
                  </Button>
                </Level.Item>
              </Level.Right>
            </Level>
          </Page.Section>
          <Page.Section>
            <Media>
              <Media.Left>
                <Icon name="lock" />
              </Media.Left>
              <Media.Content>
                <Title size="4">Charge</Title>
                {charge.name}
                {charge.code}
              </Media.Content>
            </Media>
          </Page.Section>
        </>
      ) : null}
    </Page>
  );
};

const IndexPage = () => {
  const [filters, setFilters] = React.useState({});
  const [charges, chargesMeta] = useCharges({ filters });

  return (
    <Page
      title="Charges"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Charges", to: "/charges" },
      ]}
    >
      <Page.Section>
        <Level>
          <Level.Right>
            <Button as={Link} to="/charges/new" color="success">
              <Icon name="plus" />
              <span>New Charge</span>
            </Button>
          </Level.Right>
        </Level>
        <Columns>
          <Columns.Column size="3">
            <Card>
              <Card.Header title="Search" />
              <Card.Content>
                <Form
                  disabled
                  onSubmit={setFilters}
                  loading={chargesMeta.loading}
                >
                  <Form.Input type="text" name="name" label="Name" />
                  <Level mobile>
                    <Level.Right>
                      <Level.Item>
                        <Form.SubmitButton>Search</Form.SubmitButton>
                      </Level.Item>
                    </Level.Right>
                  </Level>
                </Form>
              </Card.Content>
            </Card>
          </Columns.Column>
          <Columns.Column>
            {charges.map((charge) => (
              <Link to={`/charges/${charge.id}`} key={charge.id}>
                <ChargeCard charge={charge} />
              </Link>
            ))}
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

export const Charges = () => {
  return (
    <Switch>
      <RestrictedRoute
        exact
        path="/charges/new"
        component={CreatePage}
        permission="charge.create"
      />
      <RestrictedRoute
        exact
        path="/charges/:id/edit"
        component={EditPage}
        permission="charge.edit"
      />
      <RestrictedRoute
        exact
        path="/charges/:id"
        component={DetailsPage}
        permission="charge.read"
      />
      <RestrictedRoute
        exact
        path="/charges"
        component={IndexPage}
        permission="charge.index"
      />
    </Switch>
  );
};
