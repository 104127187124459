import PropTypes from "prop-types";
import React from "react";
import { ErrorBoundary as BaseErrorBoundary } from "react-error-boundary";
import {
  Columns,
  Menu,
  Icon,
  Level,
  Container,
  Button,
  Title,
  Breadcrumb,
  Section,
} from "@selfdetermine/react-elements";
import { matchPath, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { PermissionRequired } from "@selfdetermine/react-permissions";
import {
  useLogout,
  useAuthenticationContext,
} from "@selfdetermine/react-authentication";
import { ReactComponent as Logo } from "./logo.svg";

export const ErrorBoundary = ({ children }) => (
  <BaseErrorBoundary
    FallbackComponent={() => (
      <Section>
        <Container className="has-text-centered">
          <p className="is-size-3">Something went wrong!</p>
          <p>Try reloading the app to see if that fixes the problem</p>
          <Button
            type="button"
            onClick={() => window.location.reload(true)}
            className="mt-4"
            size="large"
          >
            Reload
          </Button>
        </Container>
      </Section>
    )}
  >
    {children}
  </BaseErrorBoundary>
);

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export const Page = ({
  title,
  subtitle,
  breadcrumbs: breadcrumbsProp = [],
  children,
}) => {
  const { user } = useAuthenticationContext();
  const [logout, logoutMeta] = useLogout();

  const breadcrumbs = (breadcrumbsProp || []).filter(
    (breadcrumb) => !!(breadcrumb.title && breadcrumb.to)
  );

  // After successfully logging out we want to just refresh the app
  React.useEffect(() => {
    if (!logoutMeta.loading && !!logoutMeta.data) {
      window.location.reload();
    }
  }, [logoutMeta.data, logoutMeta.loading]);

  return (
    <>
      <div className="navbar print-hide" style={{ zIndex: 1050 }}>
        <div className="navbar-menu is-marginless print-hide">
          {!!(title || subtitle) && (
            <div className="navbar-start">
              <div className="navbar-item">
                <div>
                  <Breadcrumb>
                    {breadcrumbs.map((breadcrumb, breadcrumbIndex) => (
                      <Breadcrumb.Item
                        as={Link}
                        key={breadcrumb.to}
                        to={breadcrumb.to}
                        active={breadcrumbIndex === breadcrumbs.length - 1}
                      >
                        {breadcrumb.title}
                      </Breadcrumb.Item>
                    ))}
                  </Breadcrumb>
                </div>
              </div>
            </div>
          )}
          <div className="navbar-end">
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link">
                {user?.person?.firstName || user.email}
              </div>
              <div className="navbar-dropdown is-boxed">
                <div className="navbar-item">
                  <Button
                    type="button"
                    onClick={() => logout()}
                    fullWidth
                    loading={logoutMeta.loading}
                  >
                    Sign out
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!!breadcrumbs.length && (
        <div className="navbar has-background-white-bis print-hide">
          <Container>
            <div className="navbar-menu">
              <div className="navbar-item">
                <Title subtitle={subtitle}>{title}</Title>
              </div>
            </div>
          </Container>
        </div>
      )}
      {children}
    </>
  );
};

Page.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      to: PropTypes.string,
    })
  ),
  children: PropTypes.node,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

Page.Section = ({ children }) => (
  <Section>
    <Container>{children}</Container>
  </Section>
);

Page.Section.propTypes = {
  children: PropTypes.node,
};

export const Layout = ({ children }) => {
  const location = useLocation();

  const navLinks = [
    {
      icon: "user",
      content: "People",
      path: "/people",
      permission: "matter.index",
      matches: !!matchPath(location.pathname, "/people"),
    },
    {
      icon: "law",
      content: "Matters",
      path: "/matters",
      permission: "matter.index",
      matches: !!matchPath(location.pathname, "/matters"),
    },
    {
      icon: "briefcase",
      content: "Counsels",
      path: "/counsels",
      permission: "counsel.index",
      matches: !!matchPath(location.pathname, "/counsels"),
    },
    {
      icon: "police",
      content: "Officers",
      path: "/officers",
      permission: "officer.index",
      matches: !!matchPath(location.pathname, "/officers"),
    },
    {
      icon: "bank",
      content: "Banks",
      path: "/banks",
      permission: "bank.index",
      matches: !!matchPath(location.pathname, "/banks"),
    },
    {
      icon: "calendar",
      content: "Hearings",
      path: "/hearings",
      permission: "hearing.index",
      matches: !!matchPath(location.pathname, "/hearings"),
    },
    {
      icon: "car",
      content: "Vehicles",
      path: "/vehicles",
      permission: "vehicle.index",
      matches: !!matchPath(location.pathname, "/vehicles"),
    },
    {
      icon: "judge",
      content: "Judges",
      path: "/judges",
      permission: "judge.index",
      matches: !!matchPath(location.pathname, "/judges"),
    },
    {
      icon: "lock",
      content: "Charges",
      path: "/charges",
      permission: "charge.index",
      matches: !!matchPath(location.pathname, "/charges"),
    },
  ];

  return (
    <Columns className="is-marginless" style={{ minHeight: "100vh" }}>
      <Columns.Column
        narrow
        className="has-background-dark is-paddingless print-hide"
      >
        <Menu style={{ position: "sticky", top: "0" }}>
          <Menu.List>
            <Menu.ListItem className="mb-6" as={Link} to="/">
              <Logo />
            </Menu.ListItem>
            {navLinks.map((link) => (
              <PermissionRequired name={link.permission} key={link.path}>
                <Menu.ListItem active={link.matches} as={Link} to={link.path}>
                  <Level className="has-text-primary-light">
                    <Level.Left>
                      <Level.Item>
                        <Icon name={link.icon} size="medium" />
                      </Level.Item>
                      <Level.Item className="is-size-5">
                        {link.content}
                      </Level.Item>
                    </Level.Left>
                  </Level>
                </Menu.ListItem>
              </PermissionRequired>
            ))}
          </Menu.List>
        </Menu>
      </Columns.Column>
      <Columns.Column className="is-paddingless">
        <ErrorBoundary>{children}</ErrorBoundary>
      </Columns.Column>
    </Columns>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
