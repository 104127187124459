import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route as UnrestrictedRoute,
} from "react-router-dom";
import { Client } from "@selfdetermine/graphql-client";
import { ClientProvider } from "@selfdetermine/react-hooks";
import { AuthenticationProvider } from "@selfdetermine/react-authentication";
import { Toaster } from "@selfdetermine/react-toasts";
import { PermissionsProvider } from "@selfdetermine/react-permissions";
import { Layout } from "./components";
import {
  PageNotFound,
  Matters,
  Counsels,
  Officers,
  Banks,
  Hearings,
  Vehicles,
  Judges,
  People,
  Charges,
} from "./components/pages";

const client = new Client({
  connection: { uri: process.env.REACT_APP_API_URL },
});

function App() {
  return (
    <ClientProvider client={client}>
      <AuthenticationProvider>
        <PermissionsProvider>
          <Router>
            <Layout>
              <Toaster />
              <Switch>
                <UnrestrictedRoute path="/people" component={People} />
                <UnrestrictedRoute path="/matters" component={Matters} />
                <UnrestrictedRoute path="/counsels" component={Counsels} />
                <UnrestrictedRoute path="/officers" component={Officers} />
                <UnrestrictedRoute path="/banks" component={Banks} />
                <UnrestrictedRoute path="/hearings" component={Hearings} />
                <UnrestrictedRoute path="/vehicles" component={Vehicles} />
                <UnrestrictedRoute path="/judges" component={Judges} />
                <UnrestrictedRoute path="/charges" component={Charges} />
                <Redirect exact from="/" to="/people" />
                <UnrestrictedRoute path="/*" component={PageNotFound} />
              </Switch>
            </Layout>
          </Router>
        </PermissionsProvider>
      </AuthenticationProvider>
    </ClientProvider>
  );
}

export default App;
