import { InMemoryCache } from '@apollo/client/cache';
import { createHttpLink, ApolloClient, ApolloLink } from '@apollo/client';
import fetch from 'unfetch';

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

/**
 * Creates a new client for interacting with the Selfdetermine GraphQL API.
 *
 * @param {object} [settings={}] - Client settings @see https://www.apollographql.com/docs/react/api/apollo-client/#optional-fields
 * @param {object} [settings.connection] - httpLink overrides @see https://www.apollographql.com/docs/link/links/http/#options
 * @returns {ApolloClient} Client for interacting with GraphQL API.
 */

var Client = function Client() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _ref$connection = _ref.connection,
      connection = _ref$connection === void 0 ? {} : _ref$connection,
      settings = _objectWithoutProperties(_ref, ["connection"]);

  var httpLink = createHttpLink(_objectSpread2(_objectSpread2({
    uri: "/graphql",
    credentials: "include"
  }, connection), {}, {
    fetch: fetch
  }));
  return new ApolloClient(_objectSpread2({
    cache: new InMemoryCache(),
    link: ApolloLink.from([httpLink])
  }, settings));
};

export { Client };
