import React from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import {
  useMatter,
  useDeleteNoteFromCase,
  AdversePartyForm,
  PrimaryPartyForm,
  DetailsForm,
  HearingsForm,
  criminalCaseTypes,
  NoteForm,
  JudgeForm,
  ChargeForm,
  useUpdateCriminal,
  OfficerForm,
  VehicleForm,
} from "@selfdetermine/react-matters";
import {
  Dropdown,
  Card,
  Menu,
  Columns,
  Tags,
  Level,
  Button,
  Icon,
  Media,
  Table,
} from "@selfdetermine/react-elements";

import {
  useLedger,
  MatterLedgerChargeForm,
  LedgerPaymentForm,
} from "@selfdetermine/react-fees";

import { PermissionRequired } from "@selfdetermine/react-permissions";
import { Toasts } from "@selfdetermine/react-toasts";
import { Page } from "../../Layout";

const PageContext = React.createContext({});

const usePageContext = () => React.useContext(PageContext);

const DisplayDate = ({ date, type }) => {
  let options = {};

  if (!date) {
    return <span className="has-text-grey">N/A</span>;
  }

  if (type === "long") {
    options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
  } else if (type === "hours") {
    options = {
      hour: "numeric",
      minute: "numeric",
    };
  }

  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
};

const AdversePartySection = () => {
  const { matter, refetch } = usePageContext();
  const [isEditing, setEditing] = React.useState(false);

  return (
    <Card className="mb-4">
      <Card.Header title="Defendant">
        <PermissionRequired name="matter.edit" context={{ matter }}>
          {!isEditing && (
            <Card.HeaderAction onClick={() => setEditing(true)}>
              Edit
            </Card.HeaderAction>
          )}
        </PermissionRequired>
      </Card.Header>

      <Card.Content>
        {isEditing ? (
          <AdversePartyForm
            matter={matter}
            onCancel={() => setEditing(false)}
            onDone={() => {
              setEditing(false);
              refetch();
              Toasts.success("Defendant updated");
            }}
          />
        ) : (
          <Menu>
            <Menu.List>
              {matter?.adverseParties?.length ? (
                matter?.adverseParties.map((party) => (
                  <Menu.ListItem
                    key={party.id}
                    as={Link}
                    to={`/people/${party.id}`}
                  >
                    <div>
                      {[party.firstName, party.middleName, party.lastName]
                        .filter(Boolean)
                        .join(" ")}
                    </div>
                    <div>
                      {" "}
                      <Tags>
                        <Tags.Tag>{party.memberStatus}</Tags.Tag>
                      </Tags>
                    </div>
                    <div className="has-text-grey is-size-7">
                      {party.membershipNumber}
                    </div>
                    <div className="has-text-grey is-size-7">
                      {party.bornAt}
                    </div>
                  </Menu.ListItem>
                ))
              ) : (
                <p className="has-text-centered is-size-4 has-text-grey-light">
                  None Selected
                </p>
              )}
            </Menu.List>
            {matter?.opposingCounsels?.length ? (
              <>
                <Menu.Label>Counsel</Menu.Label>
                <Menu.List>
                  {matter?.opposingCounsels?.map((counsel) => (
                    <Menu.ListItem
                      key={counsel.id}
                      as={Link}
                      to={`/counsels/${counsel.id}`}
                    >
                      {counsel.name}
                    </Menu.ListItem>
                  ))}
                </Menu.List>
              </>
            ) : null}
          </Menu>
        )}
      </Card.Content>
    </Card>
  );
};

const PrimaryPartySection = () => {
  const { matter, type, refetch } = usePageContext();
  const [isEditing, setEditing] = React.useState(false);
  // The primary party is considered to always be the CDA Tribe, so this section is not displayed.
  if (type === "criminal") {
    return false;
  }

  return (
    <Card className="mb-4">
      <Card.Header title="Primary Party">
        <PermissionRequired name="matter.edit" context={{ matter }}>
          {!isEditing && (
            <Card.HeaderAction onClick={() => setEditing(true)}>
              Edit
            </Card.HeaderAction>
          )}
        </PermissionRequired>
      </Card.Header>

      <Card.Content>
        {isEditing ? (
          <PrimaryPartyForm
            matter={matter}
            onCancel={() => setEditing(false)}
            onDone={() => {
              setEditing(false);
              refetch();
              Toasts.success("Primary party updated");
            }}
          />
        ) : (
          <Menu>
            <Menu.List>
              {matter?.primaryParties?.length ? (
                matter?.primaryParties.map((party) => (
                  <Menu.ListItem
                    key={party.id}
                    as={Link}
                    to={`/people/${party.id}`}
                  >
                    <div>
                      {[party.firstName, party.middleName, party.lastName]
                        .filter(Boolean)
                        .join(" ")}
                    </div>
                    <div className="has-text-grey is-size-7">
                      {party.membershipNumber}
                    </div>
                    <div>{party.bornAt}</div>
                  </Menu.ListItem>
                ))
              ) : (
                <p className="has-text-centered is-size-4 has-text-grey-light">
                  None Selected
                </p>
              )}
            </Menu.List>
            {matter?.primaryCounsels?.length ? (
              <>
                <Menu.Label>Counsel</Menu.Label>
                <Menu.List>
                  {matter?.primaryCounsels?.map((counsel) => (
                    <Menu.ListItem
                      key={counsel.id}
                      as={Link}
                      to={`/counsels/${counsel.id}`}
                    >
                      {counsel.name}
                    </Menu.ListItem>
                  ))}
                </Menu.List>
              </>
            ) : null}
          </Menu>
        )}
      </Card.Content>
    </Card>
  );
};

const JudgeSection = () => {
  const { matter, refetch } = usePageContext();
  const [isEditing, setEditing] = React.useState(false);

  return (
    <Card className="mb-4">
      <Card.Header title="Judge">
        <PermissionRequired name="matter.edit" context={{ matter }}>
          {!isEditing && (
            <Card.HeaderAction onClick={() => setEditing(true)}>
              Edit
            </Card.HeaderAction>
          )}
        </PermissionRequired>
      </Card.Header>

      <Card.Content>
        {isEditing ? (
          <JudgeForm
            matter={matter}
            onCancel={() => setEditing(false)}
            onDone={() => {
              setEditing(false);
              refetch();
              Toasts.success("Judge updated");
            }}
          />
        ) : (
          <Menu>
            <Menu.List>
              {matter?.judge ? (
                <Menu.ListItem>{matter.judge.email}</Menu.ListItem>
              ) : (
                <p className="has-text-centered is-size-4 has-text-grey-light">
                  None Selected
                </p>
              )}
            </Menu.List>
          </Menu>
        )}
      </Card.Content>
    </Card>
  );
};

const ChargesSection = () => {
  const { matter, refetch } = usePageContext();
  const [isCreating, setCreating] = React.useState(false);
  const [update, meta] = useUpdateCriminal(matter);

  const removeCharge = (chargeIndex) => {
    update({
      charges: matter.charges
        .filter((_, index) => index !== chargeIndex)
        .map(({ __typename, ...currentCharge }) => ({
          ...currentCharge,
          statute: currentCharge.statute?.id,
        })),
    });
  };

  const disabled = meta?.loading || isCreating;

  return (
    <Card className="mb-4">
      <Card.Header title="Charges">
        <PermissionRequired name="matter.edit" context={{ matter }}>
          {!disabled && (
            <Card.HeaderAction onClick={() => setCreating(true)}>
              New
            </Card.HeaderAction>
          )}
        </PermissionRequired>
      </Card.Header>

      <Card.Content>
        {disabled ? (
          <ChargeForm
            matter={matter}
            onCancel={() => {
              setCreating(false);
            }}
            onDone={() => {
              setCreating(false);
              refetch();
              Toasts.success("Charge added");
            }}
          />
        ) : (
          <Menu>
            <Menu.List>
              {matter?.charges?.length ? (
                matter.charges.map((charge, chargeIndex) => (
                  <Menu.ListItem>
                    <Level mobile className="mb-0">
                      <Level.Left>
                        <Level.Item>
                          <div>
                            {charge.statute.code} - {charge.statute.name}{" "}
                            <span className="has-text-grey is-size-7">
                              {charge.statute.revision}
                            </span>
                          </div>
                        </Level.Item>
                      </Level.Left>
                      <PermissionRequired
                        name="matter.edit"
                        context={{ matter }}
                      >
                        <Level.Right>
                          <Level.Item>
                            <Button
                              disabled={disabled}
                              onClick={() => removeCharge(chargeIndex)}
                              type="button"
                              size="small"
                              className="has-text-danger"
                            >
                              Remove
                            </Button>
                          </Level.Item>
                        </Level.Right>
                      </PermissionRequired>
                    </Level>
                    <Level>
                      <Level.Left>
                        {charge.plea && (
                          <Level.Item>
                            <Tags>
                              <Tags.Tag color="info" className="mb-1">
                                {charge.plea}
                              </Tags.Tag>
                            </Tags>
                          </Level.Item>
                        )}

                        <Level.Item>
                          <div className="has-text-grey is-size-6">
                            <DisplayDate date={charge.pledOn} />
                          </div>
                        </Level.Item>
                        <Level.Item>{charge.disposition}</Level.Item>
                      </Level.Left>
                    </Level>
                  </Menu.ListItem>
                ))
              ) : (
                <p className="has-text-centered is-size-4 has-text-grey-light">
                  None Added
                </p>
              )}
            </Menu.List>
          </Menu>
        )}
      </Card.Content>
    </Card>
  );
};

const WarrantView = () => {
  const { matter } = usePageContext();
  const warrantList = matter.warrants;
  return (
    <>
      {warrantList.length > 0 ? (
        <ul>
          {warrantList.map((warrant) => (
            <li key={warrant.id}>
              <p>ID: {warrant.id}</p>
              <p>Created: {warrant.createdAt}</p>
              <p>Modified: {warrant.modifiedAt}</p>
              <p>Description: {warrant.description}</p>
              <hr />
            </li>
          ))}
        </ul>
      ) : (
        <p>No warrants found</p>
      )}
    </>
  );
};

const WarrantSection = () => {
  return (
    <Card className="mb-4">
      <Card.Header title="Warrants" />
      <Card.Content>
        <WarrantView />
      </Card.Content>
    </Card>
  );
};

const SummarySection = () => {
  const { matter, refetch } = usePageContext();
  const [isEditing, setEditing] = React.useState(false);

  return (
    <Card className="mb-4">
      <Card.Header title="Details">
        <PermissionRequired name="matter.edit" context={{ matter }}>
          {!isEditing && (
            <Card.HeaderAction onClick={() => setEditing(true)}>
              Edit
            </Card.HeaderAction>
          )}
        </PermissionRequired>
      </Card.Header>
      <Card.Content>
        {isEditing ? (
          <DetailsForm
            matter={matter}
            onCancel={() => setEditing(false)}
            onDone={() => {
              setEditing(false);
              refetch();
              Toasts.success("Details updated");
            }}
          />
        ) : (
          <Columns className="content">
            <Columns.Column>
              <dl>
                <dt>Citation number</dt>
                <dd>{matter.citationNumber || "N/A"}</dd>
                <dt>Cited speed</dt>
                <dd>{matter.citedSpeed || "N/A"}</dd>
                <dt>Posted speed</dt>
                <dd>{matter.postedSpeed || "N/A"}</dd>
                <dt>Pounds over weight</dt>
                <dd>{matter.lbsOverWeight || "N/A"}</dd>
              </dl>
            </Columns.Column>
            <Columns.Column>
              <dl>
                <dt>Violated</dt>
                <dd>
                  <DisplayDate date={matter.violatedAt} />
                </dd>
                <dt>Created</dt>
                <dd>
                  <DisplayDate date={matter.createdAt} />
                </dd>
                <dt>Last updated</dt>
                <dd>
                  <DisplayDate date={matter.modifiedAt} />
                </dd>
                <dt>Opened</dt>
                <dd>
                  <DisplayDate date={matter.openedOn} />
                </dd>
              </dl>
            </Columns.Column>
            <Columns.Column>
              <dl>
                <dt>Filed</dt>
                <dd>
                  <DisplayDate date={matter.filedAt} />
                </dd>
                <dt>Closed</dt>
                <dd>
                  <DisplayDate date={matter.closedAt} />
                </dd>
              </dl>
            </Columns.Column>
          </Columns>
        )}
      </Card.Content>
    </Card>
  );
};

const Note = ({ note, matter, onDone, onError }) => {
  const [remove] = useDeleteNoteFromCase(matter, note);

  const deleteNote = async () => {
    try {
      const response = await remove();

      if (typeof onDone === "function") {
        onDone(response);
      }
    } catch (error) {
      if (typeof onError === "function") {
        onError(error, note);
      }
    }
  };
  return (
    <div>
      <Media>
        <Media.Left style={{ minWidth: "3.5rem" }}>
          <div className="has-text-centered py-2 has-text-grey">
            <Icon name="note" size="large" className="has-text-grey-lighter" />
            <div className="is-size-7">
              <DisplayDate date={note.createdAt} />
            </div>
            <div className="is-size-7">
              <DisplayDate date={note.createdAt} type="hours" />
            </div>
          </div>
        </Media.Left>
        <Media.Content>
          <div className="box">
            <Level mobile>
              <Level.Left>
                <Level.Item>
                  <div>
                    <p className="is-size-5">{note.body}</p>
                    <div className="has-text-grey">{note.createdBy?.email}</div>
                  </div>
                </Level.Item>
              </Level.Left>
              <PermissionRequired name="matter.edit" context={{ matter }}>
                <Level.Right>
                  <Level.Item>
                    <Dropdown alignment="right">
                      <Dropdown.Trigger removeIndicator color="text">
                        <Icon name="more" />
                      </Dropdown.Trigger>
                      <Dropdown.Content>
                        <Dropdown.Item as="a" onClick={() => deleteNote()}>
                          <Level>
                            <Level.Left>
                              <Level.Item>
                                <Icon name="trash" size="small" />
                              </Level.Item>
                              <Level.Item>Remove Note</Level.Item>
                            </Level.Left>
                          </Level>
                        </Dropdown.Item>
                      </Dropdown.Content>
                    </Dropdown>
                  </Level.Item>
                </Level.Right>
              </PermissionRequired>
            </Level>
          </div>
        </Media.Content>
      </Media>
    </div>
  );
};

Note.propTypes = {
  note: PropTypes.shape({
    body: PropTypes.string,
    createdAt: PropTypes.string,
    createdBy: PropTypes.string,
  }).isRequired,
  matter: PropTypes.shape({
    id: PropTypes.string,
    adverseParties: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      })
    ),
    opposingCounsels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }).isRequired,
  onDone: PropTypes.func,
  onError: PropTypes.func,
};

const HistoryEvent = ({ event }) => {
  const [expanded, setExpanded] = React.useState(false);
  const iconName = event.event === "create" ? "plus" : null;
  const title = {
    create: "Matter Created",
    update: "Matter Updated",
  }[event.event];

  let changes;

  try {
    changes = JSON.parse(event.changes);
  } catch (error) {
    // Unable to parse changes
  }

  return (
    <div>
      <Media>
        <Media.Left style={{ minWidth: "3.5rem" }}>
          <div className="has-text-centered py-2 has-text-grey">
            <Icon
              name={iconName}
              size="large"
              className="has-text-grey-lighter"
            />
            <div className="is-size-7">
              <DisplayDate date={event.createdAt} />
            </div>
            <div className="is-size-7">
              <DisplayDate date={event.createdAt} type="hours" />
            </div>
          </div>
        </Media.Left>
        <Media.Content style={{ paddingLeft: "1.25rem" }}>
          <p className="is-size-5">{title}</p>
          <div className="has-text-grey">{event.user?.email}</div>

          {expanded && (
            <Table
              fullwidth
              columns={[
                { id: "field", displayLabel: "Field" },
                { id: "before", displayLabel: "Previous" },
                { id: "after", displayLabel: "Value" },
              ]}
              className="mt-4"
            >
              {Object.entries(changes).map(([fieldName, [before, after]]) => (
                <Table.Row
                  key={changes.id}
                  renderCellContent={(column) => {
                    if (column.id === "field") {
                      return fieldName;
                    }

                    if (column.id === "before") {
                      return (
                        <span className="has-text-danger-dark">
                          {before || "-"}
                        </span>
                      );
                    }

                    return (
                      <span className="has-text-success-dark">
                        {after || "-"}
                      </span>
                    );
                  }}
                />
              ))}
            </Table>
          )}
        </Media.Content>
        <Media.Right>
          <Button
            type="button"
            color="light"
            onClick={() => setExpanded(!expanded)}
          >
            <span>{expanded ? "Less Details" : "More Details"}</span>
            <Icon
              name={expanded ? "chevronDown" : "chevronRight"}
              size="medium"
            />
          </Button>
        </Media.Right>
      </Media>
    </div>
  );
};

HistoryEvent.propTypes = {
  event: PropTypes.shape({
    event: PropTypes.string.isRequired,
    changes: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    user: PropTypes.shape({
      email: PropTypes.string,
    }),
  }),
};

const HistorySection = () => {
  const { matter, refetch } = usePageContext();

  const historyItems = [...matter.notes, ...matter.history].sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );

  return (
    <div className="my-6">
      {historyItems.map((item) => (
        <div key={`${item.__typename}-${item.id}`} className="my-4">
          {item.__typename === "History" ? (
            <HistoryEvent event={item} />
          ) : (
            <Note
              note={item}
              matter={matter}
              onDone={() => {
                refetch();
                Toasts.success("Note removed");
              }}
            />
          )}
        </div>
      ))}
      <PermissionRequired name="matter.update">
        <div className="my-6">
          <NoteForm
            matter={matter}
            onDone={() => {
              refetch();
              Toasts.success("Note added");
            }}
          />
        </div>
      </PermissionRequired>
    </div>
  );
};

const HearingsDetails = ({ setEditTarget }) => {
  const { matter } = usePageContext();

  return (
    <Columns className="content">
      {matter.hearings.length ? (
        <Columns.Column>
          {matter.hearings.map((hearing) => {
            return (
              <Level>
                <Level.Left>
                  <DisplayDate type="long" date={hearing.startedAt} />
                </Level.Left>
                <Level.Right>
                  {hearing.kind}
                  <Button onClick={() => setEditTarget(hearing.id)}>
                    Edit
                  </Button>
                </Level.Right>
              </Level>
            );
          })}
        </Columns.Column>
      ) : (
        <Columns.Column>
          <dl>
            <dt className="has-text-centered is-size-4 has-text-grey-light">
              None Scheduled
            </dt>
          </dl>
        </Columns.Column>
      )}
    </Columns>
  );
};
HearingsDetails.propTypes = {
  setEditTarget: PropTypes.func,
};

const HearingsSection = () => {
  const { matter, refetch } = usePageContext();
  const [editTarget, setEditTarget] = React.useState(false);
  const [isAdding, setAdding] = React.useState(false);

  return (
    <Card className="mb-4">
      <Card.Header title="Hearings">
        <PermissionRequired name="matter.edit" context={{ matter }}>
          {!editTarget && !isAdding && (
            <Card.HeaderAction onClick={() => setAdding(true)}>
              New
            </Card.HeaderAction>
          )}
        </PermissionRequired>
      </Card.Header>
      <Card.Content>
        {editTarget || isAdding ? (
          <HearingsForm
            matter={matter}
            isAdding={isAdding}
            editTarget={editTarget}
            onCancel={() =>
              editTarget ? setEditTarget(false) : setAdding(false)
            }
            onDone={() => {
              if (editTarget) setEditTarget(false);
              else setAdding(false);
              refetch();
              Toasts.success("Hearings updated");
            }}
          />
        ) : (
          <HearingsDetails matter={matter} setEditTarget={setEditTarget} />
        )}
      </Card.Content>
    </Card>
  );
};

const Price = ({ value }) => {
  if (Number.isNaN(Number(value))) {
    return null;
  }

  const formattedPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(Number(value));

  return formattedPrice;
};

const LedgerPaymentMenuItem = ({ payment }) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <Menu.ListItem role="button" onClick={() => setExpanded(!expanded)}>
      <Level mobile className="mb-0">
        <Level.Left>
          <Level.Item>{payment.paymentMethod}</Level.Item>
        </Level.Left>
        <Level.Right>
          <Level.Item className="has-text-success-dark">
            <Price value={payment.amount * -1} />
          </Level.Item>
          <Level.Item>
            <Icon
              name={expanded ? "chevronDown" : "chevronRight"}
              className="has-text-grey-lighter"
            />
          </Level.Item>
        </Level.Right>
      </Level>
      {expanded && (
        <Level className="is-size-7 has-text-grey">
          <Level.Left>
            <Level.Item>
              <div>
                <DisplayDate date={payment.createdAt} type="long" />
              </div>
            </Level.Item>
          </Level.Left>
        </Level>
      )}
    </Menu.ListItem>
  );
};

LedgerPaymentMenuItem.propTypes = {
  payment: PropTypes.shape({
    createdAt: PropTypes.string,
    amount: PropTypes.number,
    paymentMethod: PropTypes.string,
  }),
};

const LedgerChargeMenuItem = ({ charge }) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <Menu.ListItem role="button" onClick={() => setExpanded(!expanded)}>
      <Level mobile className="mb-0">
        <Level.Left>
          <Level.Item>{charge.description}</Level.Item>
        </Level.Left>
        <Level.Right>
          <Level.Item className="has-text-danger-dark">
            <Price value={charge.amount} />
          </Level.Item>
          <Level.Item>
            <Icon
              name={expanded ? "chevronDown" : "chevronRight"}
              className="has-text-grey-lighter"
            />
          </Level.Item>
        </Level.Right>
      </Level>
      {expanded && (
        <Level className="is-size-7 has-text-grey">
          <Level.Left>
            <Level.Item>
              <div>
                <DisplayDate date={charge.createdAt} type="long" />
              </div>
            </Level.Item>
          </Level.Left>
        </Level>
      )}
    </Menu.ListItem>
  );
};

LedgerChargeMenuItem.propTypes = {
  charge: PropTypes.shape({
    amount: PropTypes.number,
    description: PropTypes.string,
    createdAt: PropTypes.string,
  }),
};

const LedgerSection = () => {
  const [state, setState] = React.useState("idle");
  const { matter, refetch } = usePageContext();
  const [ledger, ledgerMeta] = useLedger({ id: matter.id });

  if (ledgerMeta.loading) {
    return null;
  }

  const items = (ledger ? [...ledger.charges, ...ledger.payments] : []).sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );

  const canCreatePayment = !!ledger;

  let Form = null;

  if (state === "create:charge") {
    Form = () => (
      <MatterLedgerChargeForm
        matter={matter}
        onDone={() => {
          ledgerMeta.refetch();
          refetch();
          Toasts.success("Charge added to ledger");
          setState("idle");
        }}
        onCancel={() => {
          setState("idle");
        }}
      />
    );
  } else if (state === "create:payment") {
    Form = () => (
      <LedgerPaymentForm
        ledger={ledger}
        onDone={() => {
          ledgerMeta.refetch();
          refetch();
          Toasts.success("Payment added to ledger");
          setState("idle");
        }}
        onCancel={() => {
          setState("idle");
        }}
      />
    );
  }

  return (
    <Card className="mb-4">
      <Card.Header title="Ledger">
        <PermissionRequired name="matter.edit" context={{ matter }}>
          {state === "idle" && (
            <Dropdown alignment="right">
              <Dropdown.Trigger
                aria-label="Ledger actions"
                as={Card.HeaderAction}
                removeIndicator
              >
                <Icon name="more" />
              </Dropdown.Trigger>
              <Dropdown.Content>
                <Dropdown.Item
                  as="a"
                  role="button"
                  onClick={() => setState("create:charge")}
                >
                  <Level mobile>
                    <Level.Left>
                      <Level.Item>
                        <Icon name="plus" size="small" />
                      </Level.Item>
                      <Level.Item>Add Charge</Level.Item>
                    </Level.Left>
                  </Level>
                </Dropdown.Item>
                {canCreatePayment ? (
                  <Dropdown.Item
                    as="a"
                    role="button"
                    onClick={() => setState("create:payment")}
                  >
                    <Level mobile>
                      <Level.Left>
                        <Level.Item>
                          <Icon name="plus" size="small" />
                        </Level.Item>
                        <Level.Item>Add Payment</Level.Item>
                      </Level.Left>
                    </Level>
                  </Dropdown.Item>
                ) : null}
              </Dropdown.Content>
            </Dropdown>
          )}
        </PermissionRequired>
      </Card.Header>
      <Card.Content>
        {state === "idle" ? (
          <Menu>
            <Menu.List>
              {items.map((ledgerItem) =>
                ledgerItem.__typename === "Payment" ? (
                  <LedgerPaymentMenuItem
                    payment={ledgerItem}
                    key={ledgerItem.id}
                  />
                ) : (
                  <LedgerChargeMenuItem
                    charge={ledgerItem}
                    key={ledgerItem.id}
                  />
                )
              )}
              {!items.length ? (
                <p className="has-text-centered is-size-4 has-text-grey-light">
                  No charges or payments
                </p>
              ) : (
                <>
                  <hr />
                  <Menu.ListItem>
                    <Level mobile>
                      <Level.Left>
                        <Level.Item className="has-text-weight-medium">
                          Balance due
                        </Level.Item>
                      </Level.Left>
                      <Level.Right>
                        <Level.Item
                          className={[
                            "has-text-weight-medium is-size-4",
                            ledger?.balance < 0 && "has-text-success-dark",
                            ledger?.balance > 0 && "has-text-danger-dark",
                          ]
                            .filter(Boolean)
                            .join(" ")}
                        >
                          <Price value={ledger?.balance} />
                        </Level.Item>
                        <Level.Item>
                          {/* Placeholder to align balance with charges/payments above */}
                          <div style={{ width: "1.5rem" }} />
                        </Level.Item>
                      </Level.Right>
                    </Level>
                  </Menu.ListItem>
                </>
              )}
            </Menu.List>
          </Menu>
        ) : (
          <Form />
        )}
      </Card.Content>
    </Card>
  );
};

const OfficersSection = () => {
  const { matter, type, refetch } = usePageContext();
  const [isEditing, setEditing] = React.useState(false);

  if (type === "criminal") {
    return (
      <Card className="mb-4">
        <Card.Header title="Officers">
          <PermissionRequired name="matter.edit" context={{ matter }}>
            {!isEditing && (
              <Card.HeaderAction onClick={() => setEditing(true)}>
                Add
              </Card.HeaderAction>
            )}
          </PermissionRequired>
        </Card.Header>

        <Card.Content>
          {isEditing ? (
            <OfficerForm
              matter={matter}
              onCancel={() => setEditing(false)}
              onDone={() => {
                setEditing(false);
                refetch();
                Toasts.success("Officers updated");
              }}
            />
          ) : (
            <Menu>
              <Menu.List>
                {matter?.officers?.length ? (
                  matter?.officers.map((officer) => (
                    <Menu.ListItem
                      key={officer.id}
                      as={Link}
                      to={`/people/${officer.id}`}
                    >
                      <div>{officer.name}</div>
                      <div className="has-text-grey is-size-7">
                        {officer.badgeNumber}
                      </div>
                      <div>{officer.agency}</div>
                    </Menu.ListItem>
                  ))
                ) : (
                  <p className="has-text-centered is-size-4 has-text-grey-light">
                    None Selected
                  </p>
                )}
              </Menu.List>
            </Menu>
          )}
        </Card.Content>
      </Card>
    );
  }
  return false;
};

const VehiclesSection = () => {
  const { matter, refetch } = usePageContext();
  const [isEditing, setEditing] = React.useState(false);

  return (
    <Card className="mb-4">
      <Card.Header title="Vehicle">
        <PermissionRequired name="matter.edit" context={{ matter }}>
          {!isEditing && (
            <Card.HeaderAction onClick={() => setEditing(true)}>
              Edit
            </Card.HeaderAction>
          )}
        </PermissionRequired>
      </Card.Header>
      <Card.Content>
        {isEditing ? (
          <VehicleForm
            matter={matter}
            onCancel={() => setEditing(false)}
            onDone={() => {
              setEditing(false);
              refetch();
              Toasts.success("Vehicle updated");
            }}
          />
        ) : (
          <Columns className="content">
            {matter.vehicles?.length ? (
              <Columns.Column>
                {matter.vehicles.map((vehicle) => {
                  return (
                    <dl>
                      <dt>
                        {`${vehicle.make} ${vehicle.plateNumber} ${vehicle.state}`}
                      </dt>
                    </dl>
                  );
                })}
              </Columns.Column>
            ) : (
              <Columns.Column>
                <dl>
                  <dt className="has-text-centered is-size-4 has-text-grey-light">
                    None Selected
                  </dt>
                </dl>
              </Columns.Column>
            )}
          </Columns>
        )}
      </Card.Content>
    </Card>
  );
};

export const Details = () => {
  const { id } = useParams();
  const [matter, meta] = useMatter({ id });
  const type = criminalCaseTypes[matter?.kind] ? "criminal" : "civil";

  if (meta.loading) {
    return null;
  }

  const title = matter?.caseNumber;

  return (
    <PageContext.Provider value={{ matter, refetch: meta.refetch, type }}>
      <Page
        title={title}
        breadcrumbs={[
          { title: "Home", to: "/" },
          { title: "Matters", to: "/matters" },
          {
            title: `${matter?.caseNumber}`,
            to: `/people/${matter?.id}`,
          },
        ]}
      >
        <Page.Section>
          <Level className="mb-4">
            <Level.Left>
              <Level.Item>
                <Tags>
                  <Tags.Tag size="medium" color="primary" label="status">
                    <p>{matter.status}</p>
                  </Tags.Tag>
                  <Tags.Tag size="medium" color="success" label="type">
                    <p>{matter.kind}</p>
                  </Tags.Tag>
                </Tags>
              </Level.Item>
            </Level.Left>
            <Level.Right>
              <PermissionRequired name="matter.sendTo" context={{ matter }}>
                <Level.Item>
                  <Button type="button" disabled color="primary">
                    <Icon name="send" />
                    <span>Send to Prosecutor</span>
                  </Button>
                </Level.Item>
              </PermissionRequired>
            </Level.Right>
          </Level>
          <Columns>
            <Columns.Column size="4">
              <PrimaryPartySection />
              <AdversePartySection />
              <JudgeSection />
              <ChargesSection />
              <WarrantSection />
              <OfficersSection />
              <HearingsSection />
              <VehiclesSection />
              <LedgerSection />
            </Columns.Column>
            <Columns.Column>
              <SummarySection />
              <HistorySection />
            </Columns.Column>
          </Columns>
        </Page.Section>
      </Page>
    </PageContext.Provider>
  );
};
