import React from "react";
import { MoveFocusInside } from "react-focus-lock";
import { Switch, Link, useHistory, useParams } from "react-router-dom";
import { Toasts } from "@selfdetermine/react-toasts";
import {
  Button,
  Level,
  Columns,
  Icon,
  Card,
} from "@selfdetermine/react-elements";
import { Form } from "@selfdetermine/react-forms";
import {
  OfficerCard,
  useOfficer,
  useOfficers,
  OfficerForm,
} from "@selfdetermine/react-contactables";
import { RestrictedRoute } from "../RestrictedRoute";
import { Page } from "../Layout";

const CreatePage = () => {
  const history = useHistory();

  return (
    <Page
      title="New Officer"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Officers", to: "/officers" },
        { title: "New", to: "/officers/new" },
      ]}
    >
      <Page.Section>
        <Columns centered>
          <Columns.Column size="6">
            <MoveFocusInside>
              <OfficerForm
                onDone={() => {
                  Toasts.success("New officer created");
                  history.push("/officers");
                }}
                onCancel={() => history.push("/officers")}
              />
            </MoveFocusInside>
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

const EditPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const [officer, officerMeta] = useOfficer({ id });

  if (!officer && !officerMeta.loading) {
    history.push("/officers");
  }

  if (officer.loading) {
    return false;
  }

  return (
    <Page
      title={officer?.name}
      subtitle="Update"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Officers", to: "/officers" },
        { title: officer?.name, to: `/officers/${officer?.id}` },
        { title: "Update", to: `/officers/${officer?.id}/edit` },
      ]}
    >
      <Page.Section>
        <Columns centered>
          <Columns.Column size="6">
            <MoveFocusInside>
              <OfficerForm
                defaultValues={officer}
                onDone={() => {
                  Toasts.success("Officer updated");
                  history.push(`/officers/${officer?.id}`);
                }}
                onCancel={() => history.push(`/officers/${officer?.id}`)}
              />
            </MoveFocusInside>
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

const DetailsPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const [officer, officerMeta] = useOfficer({ id });

  if (!officer && !officerMeta.loading) {
    history.push("/officers");
  }

  return (
    <Page
      subtitle="Officer Details"
      title={officer?.name}
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Officers", to: "/officers" },
        { title: officer?.name, to: `/officers/${officer?.id}` },
      ]}
    >
      {officer ? (
        <Page.Section>
          <Level mobile>
            <Level.Right>
              <Level.Item>
                <Button as={Link} to={`/officers/${officer.id}/edit`}>
                  <Icon name="pencil" />
                  <span>Edit</span>
                </Button>
              </Level.Item>
            </Level.Right>
          </Level>
        </Page.Section>
      ) : null}
    </Page>
  );
};

const IndexPage = () => {
  const [filters, setFilters] = React.useState({});
  const [officers, officersMeta] = useOfficers({ filters });

  return (
    <Page
      title="Officers"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Officers", to: "/officers" },
      ]}
    >
      <Page.Section>
        <Level>
          <Level.Right>
            <Button as={Link} to="/officers/new" color="success">
              <Icon name="plus" />
              <span>New Officer</span>
            </Button>
          </Level.Right>
        </Level>
        <Columns>
          <Columns.Column size="3">
            <Card>
              <Card.Header title="Search" />
              <Card.Content>
                <Form
                  disabled
                  onSubmit={setFilters}
                  loading={officersMeta.loading}
                >
                  <Form.Input type="search" name="name" label="Name" />
                  <Form.Input
                    type="search"
                    name="badgeNumber"
                    label="Badge number"
                  />
                  <Level mobile>
                    <Level.Right>
                      <Level.Item>
                        <Form.SubmitButton>Search</Form.SubmitButton>
                      </Level.Item>
                    </Level.Right>
                  </Level>
                </Form>
              </Card.Content>
            </Card>
          </Columns.Column>
          <Columns.Column>
            {officers.map((officer) => (
              <Link to={`/officers/${officer.id}`} key={officer.id}>
                <OfficerCard officer={officer} />
              </Link>
            ))}
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

export const Officers = () => {
  return (
    <Switch>
      <RestrictedRoute
        exact
        path="/officers/new"
        component={CreatePage}
        permission="officer.create"
      />
      <RestrictedRoute
        exact
        path="/officers/:id/edit"
        component={EditPage}
        permission="officer.edit"
      />
      <RestrictedRoute
        exact
        path="/officers/:id"
        component={DetailsPage}
        permission="officer.read"
      />
      <RestrictedRoute
        exact
        path="/officers"
        component={IndexPage}
        permission="officer.index"
      />
    </Switch>
  );
};
