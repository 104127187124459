import React from "react";
import { MoveFocusInside } from "react-focus-lock";
import { Switch, Link, useHistory, useParams } from "react-router-dom";
import { Toasts } from "@selfdetermine/react-toasts";
import {
  Button,
  Level,
  Columns,
  Media,
  Icon,
  Card,
  Title,
} from "@selfdetermine/react-elements";
import { Form } from "@selfdetermine/react-forms";
import {
  BankCard,
  useBank,
  useBanks,
  BankForm,
} from "@selfdetermine/react-contactables";
import { RestrictedRoute } from "../RestrictedRoute";
import { Page } from "../Layout";

const CreatePage = () => {
  const history = useHistory();

  return (
    <Page
      title="New Bank"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Banks", to: "/banks" },
        { title: "New", to: "/banks/new" },
      ]}
    >
      <Page.Section>
        <Columns centered>
          <Columns.Column size="6">
            <MoveFocusInside>
              <BankForm
                onDone={() => {
                  Toasts.success("New bank created");
                  history.push("/banks");
                }}
                onCancel={() => history.push("/banks")}
              />
            </MoveFocusInside>
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

const EditPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const [bank, bankMeta] = useBank({ id });

  if (!bank && !bankMeta.loading) {
    history.push("/banks");
  }

  if (bank.loading) {
    return false;
  }

  return (
    <Page
      title={bank?.name}
      subtitle="Update"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Banks", to: "/banks" },
        { title: bank?.name, to: `/banks/${bank?.id}` },
        { title: "Update", to: `/banks/${bank?.id}/edit` },
      ]}
    >
      <Page.Section>
        <Columns centered>
          <Columns.Column size="6">
            <MoveFocusInside>
              <BankForm
                defaultValues={bank}
                onDone={() => {
                  Toasts.success("Bank updated");
                  history.push(`/banks/${bank?.id}`);
                }}
                onCancel={() => history.push(`/banks/${bank?.id}`)}
              />
            </MoveFocusInside>
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

const DetailsPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const [bank, bankMeta] = useBank({ id });

  if (!bank && !bankMeta.loading) {
    history.push("/banks");
  }

  return (
    <Page
      subtitle="Bank Details"
      title={bank?.name}
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Banks", to: "/banks" },
        { title: bank?.name, to: `/banks/${bank?.id}` },
      ]}
    >
      {bank ? (
        <>
          <Page.Section>
            <Level mobile>
              <Level.Right>
                <Level.Item>
                  <Button as={Link} to={`/banks/${bank.id}/edit`}>
                    <Icon name="pencil" />
                    <span>Edit</span>
                  </Button>
                </Level.Item>
              </Level.Right>
            </Level>
          </Page.Section>
          <Page.Section>
            <Media>
              <Media.Left>
                <Icon name="phone" />
              </Media.Left>
              <Media.Content>
                <Title size="4">Phone</Title>
                {bank.phone ? (
                  <a href={`tel:${bank.phone}`}>{bank.phone}</a>
                ) : (
                  "No phone available"
                )}

                <address>
                  <div>{bank?.street1}</div>
                  <div>{bank?.street2}</div>
                  <div>
                    {[bank?.city, bank?.state, bank?.postalCode]
                      .filter(Boolean)
                      .join(", ")}
                  </div>
                  <div>{bank?.phoneNumber}</div>
                </address>
              </Media.Content>
            </Media>
            <Media>
              <Media.Left>
                <Icon name="map" />
              </Media.Left>
              <Media.Content>
                <Title size="4">Address</Title>
                <address>
                  <div>{bank?.street1}</div>
                  <div>{bank?.street2}</div>
                  <div>
                    {[bank?.city, bank?.state, bank?.postalCode]
                      .filter(Boolean)
                      .join(", ")}
                  </div>
                  <div>{bank?.phoneNumber}</div>
                </address>
              </Media.Content>
            </Media>
          </Page.Section>
        </>
      ) : null}
    </Page>
  );
};

const IndexPage = () => {
  const [filters, setFilters] = React.useState({});
  const [banks, banksMeta] = useBanks({ filters });

  return (
    <Page
      title="Banks"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Banks", to: "/banks" },
      ]}
    >
      <Page.Section>
        <Level>
          <Level.Right>
            <Button as={Link} to="/banks/new" color="success">
              <Icon name="plus" />
              <span>New Bank</span>
            </Button>
          </Level.Right>
        </Level>
        <Columns>
          <Columns.Column size="3">
            <Card>
              <Card.Header title="Search" />
              <Card.Content>
                <Form
                  disabled
                  onSubmit={setFilters}
                  loading={banksMeta.loading}
                >
                  <Form.Input type="search" name="name" label="Name" />
                  <Level mobile>
                    <Level.Right>
                      <Level.Item>
                        <Form.SubmitButton>Search</Form.SubmitButton>
                      </Level.Item>
                    </Level.Right>
                  </Level>
                </Form>
              </Card.Content>
            </Card>
          </Columns.Column>
          <Columns.Column>
            {banks.map((bank) => (
              <Link to={`/banks/${bank.id}`} key={bank.id}>
                <BankCard bank={bank} />
              </Link>
            ))}
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

export const Banks = () => {
  return (
    <Switch>
      <RestrictedRoute
        exact
        path="/banks/new"
        component={CreatePage}
        permission="bank.create"
      />
      <RestrictedRoute
        exact
        path="/banks/:id/edit"
        component={EditPage}
        permission="bank.edit"
      />
      <RestrictedRoute
        exact
        path="/banks/:id"
        component={DetailsPage}
        permission="bank.read"
      />
      <RestrictedRoute
        exact
        path="/banks"
        component={IndexPage}
        permission="bank.index"
      />
    </Switch>
  );
};
