import React from "react";
import { MoveFocusInside } from "react-focus-lock";
import { Switch, Link, useHistory, useParams } from "react-router-dom";
import { gql } from "@selfdetermine/graphql";
import { useQuery } from "@selfdetermine/react-hooks";
import { Toasts } from "@selfdetermine/react-toasts";
import { Button, Level, Columns, Icon } from "@selfdetermine/react-elements";
import { CounselTable, CounselForm } from "@selfdetermine/react-contactables";
import { RestrictedRoute } from "../RestrictedRoute";
import { Page } from "../Layout";

const NewCounselPage = () => {
  const history = useHistory();

  return (
    <Page
      title="New Counsel"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Counsels", to: "/counsels" },
        { title: "New", to: "/counsels/new" },
      ]}
    >
      <Page.Section>
        <Columns centered>
          <Columns.Column size="6">
            <MoveFocusInside>
              <CounselForm
                onDone={() => {
                  Toasts.success("New counsel created");
                  history.push("/counsels");
                }}
                onCancel={() => history.push("/counsels")}
              />
            </MoveFocusInside>
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

const EditPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const query = useQuery(
    gql`
      query Counsel($id: ID!) {
        viewer {
          counsel(id: $id) {
            id
            name
          }
        }
      }
    `,
    { variables: { id } }
  );

  const counsel = query?.data?.viewer?.counsel;

  if (!counsel && !query.loading) {
    history.push("/counsels");
  }

  if (query.loading) {
    return false;
  }

  return (
    <Page
      title={counsel?.name}
      subtitle="Update"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Counsels", to: "/counsels" },
        { title: counsel?.name, to: `/counsels/${counsel?.id}` },
        { title: "Update", to: `/counsels/${counsel?.id}/edit` },
      ]}
    >
      <Page.Section>
        <Columns centered>
          <Columns.Column size="6">
            <MoveFocusInside>
              <CounselForm
                defaultValues={counsel}
                onDone={() => {
                  Toasts.success("Counsel updated");
                  history.push(`/counsels/${counsel?.id}`);
                }}
                onCancel={() => history.push(`/counsels/${counsel?.id}`)}
              />
            </MoveFocusInside>
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

const DetailsPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const query = useQuery(
    gql`
      query Counsel($id: ID!) {
        viewer {
          counsel(id: $id) {
            id
            name
          }
        }
      }
    `,
    { variables: { id } }
  );

  const counsel = query?.data?.viewer?.counsel;

  if (!counsel && !query.loading) {
    history.push("/counsels");
  }

  return (
    <Page
      subtitle="Counsel Details"
      title={counsel?.name}
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Counsels", to: "/counsels" },
        { title: counsel?.name, to: `/counsels/${counsel?.id}` },
      ]}
    >
      {counsel ? (
        <Page.Section>
          <Level mobile>
            <Level.Right>
              <Level.Item>
                <Button as={Link} to={`/counsels/${counsel.id}/edit`}>
                  <Icon name="pencil" />
                  <span>Edit</span>
                </Button>
              </Level.Item>
            </Level.Right>
          </Level>
        </Page.Section>
      ) : null}
    </Page>
  );
};

export const CounselsIndexPage = () => {
  const history = useHistory();

  return (
    <Page
      title="Counsels"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "Counsels", to: "/counsels" },
      ]}
    >
      <Page.Section>
        <Level>
          <Level.Right>
            <Button as={Link} to="/counsels/new" color="success">
              <Icon name="plus" />
              <span>New Counsel</span>
            </Button>
          </Level.Right>
        </Level>
        <CounselTable
          onSelect={(counsel) => {
            history.push(`/counsels/${counsel.id}`);
          }}
        />
      </Page.Section>
    </Page>
  );
};

export const Counsels = () => {
  return (
    <Switch>
      <RestrictedRoute
        exact
        path="/counsels/new"
        component={NewCounselPage}
        permission="counsel.create"
      />
      <RestrictedRoute
        exact
        path="/counsels/:id/edit"
        component={EditPage}
        permission="counsel.edit"
      />
      <RestrictedRoute
        exact
        path="/counsels/:id"
        component={DetailsPage}
        permission="counsel.read"
      />
      <RestrictedRoute
        exact
        path="/counsels"
        component={CounselsIndexPage}
        permission="counsel.index"
      />
    </Switch>
  );
};
