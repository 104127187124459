import React from "react";
import { Title, Button } from "@selfdetermine/react-elements";
import { useHistory } from "react-router-dom";
import { Page } from "../Layout";

export const PageNotFound = () => {
  const history = useHistory();

  return (
    <Page title="Content not found">
      <Page.Section>
        <div className="has-text-centered">
          <Title
            alignment="centered"
            size="1"
            subtitle="Either it doesn't exist, or you don't have the right permissions to see it. If you feel like this is a mistake, please report this issue to an administrator."
          >
            Sorry! We were unable to find what you&apos;re looking for!
          </Title>
        </div>
      </Page.Section>
      <Page.Section>
        <div className="has-text-centered">
          <Button size="large" type="button" onClick={() => history.goBack()}>
            Go Back
          </Button>
        </div>
      </Page.Section>
    </Page>
  );
};
