import React from "react";
import { MoveFocusInside } from "react-focus-lock";
import { Switch, Link, useHistory, useParams } from "react-router-dom";
import { gql } from "@selfdetermine/graphql";
import { useQuery } from "@selfdetermine/react-hooks";
import { Toasts } from "@selfdetermine/react-toasts";
import {
  Button,
  Level,
  Columns,
  Icon,
  Title,
  Card,
} from "@selfdetermine/react-elements";
import { PersonForm, Search } from "@selfdetermine/react-people";
import { NewCriminalCaseForm } from "@selfdetermine/react-matters";
import { RestrictedRoute } from "../RestrictedRoute";
import { Page } from "../Layout";

const NewPersonPage = () => {
  const history = useHistory();

  return (
    <Page
      title="New Person"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "People", to: "/people" },
        { title: "New", to: "/people/new" },
      ]}
    >
      <Page.Section>
        <Columns centered>
          <Columns.Column size="6">
            <MoveFocusInside>
              <PersonForm
                onDone={() => {
                  Toasts.success("New person created");
                  history.push("/people");
                }}
                onCancel={() => history.push("/people")}
              />
            </MoveFocusInside>
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

const EditPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const query = useQuery(
    gql`
      query Person($person: ID!) {
        viewer {
          person(id: $person) {
            id
            firstName
            lastName
            bornAt
            sex
          }
        }
      }
    `,
    { variables: { person: id } }
  );

  const person = query?.data?.viewer?.person;

  if (!person && !query.loading) {
    history.push("/people");
  }

  if (query.loading) {
    return false;
  }

  return (
    <Page
      title={`${person?.firstName} ${person?.lastName}`}
      subtitle="Update"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "People", to: "/people" },
        {
          title: `${person?.firstName} ${person?.lastName}`,
          to: `/people/${person?.id}`,
        },
        { title: "Update", to: `/people/${person?.id}/edit` },
      ]}
    >
      <Page.Section>
        <Columns centered>
          <Columns.Column size="6">
            <MoveFocusInside>
              <PersonForm
                defaultValues={person}
                onDone={() => {
                  Toasts.success("Person updated");
                  history.push(`/people/${person?.id}`);
                }}
                onCancel={() => history.push(`/people/${person?.id}`)}
              />
            </MoveFocusInside>
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

const AttachedPersonCases = (props) => {
  // eslint-disable-next-line react/prop-types
  const { casenumber, caseid } = props;
  return (
    <div>
      {casenumber} <Link to={`/matters/${caseid}`}>View case {caseid}</Link>
    </div>
  );
};

const DetailsPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const query = useQuery(
    gql`
      query Person($person: ID!) {
        viewer {
          person(id: $person) {
            id
            firstName
            lastName
            bornAt
            sex
            adversePartyFor {
              id
              caseNumber
            }
          }
        }
      }
    `,
    { variables: { person: id } }
  );

  const person = query?.data?.viewer?.person;
  if (!person && !query.loading) {
    history.push("/people");
  }

  return (
    <Page
      title={`${person?.firstName} ${person?.lastName}`}
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "People", to: "/people" },
        {
          title: `${person?.firstName} ${person?.lastName}`,
          to: `/people/${person?.id}`,
        },
      ]}
    >
      {person ? (
        <Page.Section>
          <Level mobile>
            <Level.Right>
              <Level.Item>
                <Button as={Link} to={`/people/${person.id}/edit`}>
                  <Icon name="edit" />
                  <span>Edit</span>
                </Button>
                <Button
                  as={Link}
                  to={`/people/${person.id}/matters/criminal/new`}
                  color="success"
                >
                  <Icon name="law" />
                  <span>New Criminal Case</span>
                </Button>
              </Level.Item>
            </Level.Right>
          </Level>
          <Card>
            <Card.Header>
              <p className="card-header-title">Information</p>
            </Card.Header>
            <Card.Content>
              <p className="is-size-7 has-text-weight-bold has-text-grey">
                First Name
              </p>
              {person.firstName}
              <p className="is-size-7 has-text-weight-bold has-text-grey mt-2">
                Last Name
              </p>
              {person.lastName}
              <p className="is-size-7 has-text-weight-bold has-text-grey mt-2">
                Birthdate
              </p>
              {person.bornAt}
              <p className="is-size-7 has-text-weight-bold has-text-grey mt-2">
                Gender
              </p>
              <p className="is-capitalized">{person.sex}</p>
            </Card.Content>
          </Card>

          <Card className="mt-6">
            <Card.Header>
              <p className="card-header-title">Cases</p>
            </Card.Header>
            <Card.Content>
              {person.adversePartyFor.map((item) => (
                <AttachedPersonCases
                  casenumber={item.caseNumber}
                  caseid={item.id}
                />
              ))}
            </Card.Content>
          </Card>
        </Page.Section>
      ) : null}
    </Page>
  );
};

export const PeoplePage = () => {
  const history = useHistory();

  return (
    <Page
      title="People"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "People", to: "/people" },
      ]}
    >
      <Page.Section>
        <Level>
          <Level.Left>
            <Title>Search People</Title>
          </Level.Left>
          <Level.Right>
            <Button as={Link} to="/people/new" color="success">
              <Icon name="plus" />
              <span>New Person</span>
            </Button>
          </Level.Right>
        </Level>
        <Search
          onSelect={(person) => {
            history.push(`/people/${person.id}`);
          }}
        />
      </Page.Section>
    </Page>
  );
};

export const NewMatterPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const query = useQuery(
    gql`
      query Person($person: ID!) {
        viewer {
          person(id: $person) {
            id
            firstName
            lastName
          }
        }
      }
    `,
    { variables: { person: id } }
  );

  const person = query?.data?.viewer?.person;

  if (query.loading) {
    return false;
  }

  return (
    <Page
      title="New Criminal Case"
      breadcrumbs={[
        { title: "Home", to: "/" },
        { title: "People", to: "/people" },
        {
          title: `${person?.firstName} ${person?.lastName}`,
          to: `/people/${person?.id}`,
        },
        {
          title: `New Criminal Case`,
          to: `/people/${id}/matters/criminal/new`,
        },
      ]}
    >
      <Page.Section>
        <Columns centered>
          <Columns.Column size="8">
            <NewCriminalCaseForm
              defendant={person}
              onCancel={() => {
                history.push(`/people/${id}`);
              }}
              onDone={(response) => {
                history.push(`/matters/${response?.data?.matter?.id}`);
                Toasts.success(`New criminal case created`);
              }}
            />
          </Columns.Column>
        </Columns>
      </Page.Section>
    </Page>
  );
};

export const People = () => {
  return (
    <Switch>
      <RestrictedRoute
        exact
        path="/people/new"
        component={NewPersonPage}
        permission="person.create"
      />
      <RestrictedRoute
        exact
        path="/people/:id/edit"
        component={EditPage}
        permission="person.edit"
      />
      <RestrictedRoute
        exact
        path="/people/:id"
        component={DetailsPage}
        permission="person.read"
      />
      <RestrictedRoute
        exact
        path="/people"
        component={PeoplePage}
        permission="person.read"
      />
      <RestrictedRoute
        exact
        path="/people/:id/matters/criminal/new"
        component={NewMatterPage}
        permission="matter.create"
      />
    </Switch>
  );
};
